export const HOME_PAGE= "/:lang";
export const HOME = "/";
export const FEATURES = "/:lang/features";
export const HOW_IT_WORKS = "/:lang/how-it-works";
export const FAQ = "/:lang/faq";
export const COMPANY = "/:lang/company";
export const TERMS_OF_SERVICE ="/:lang/terms-of-use";
export const REQUEST_DEMO ="/:lang/request-demo";
export const CONTACT_PAGE ="/:lang/contact";
export const PRICING_PAGE ="/:lang/pricing";
export const COOKIE_TEXT = "/:lang/cookie-policy";
export const CONTACT_FORM = "/:lang/contact-form";
export const NOT_FOUND_PAGE ="/:lang/404";


export const routePaths ={
    trLang:"/tr",
    enLang:"/en",
    home:{
        routeName:"home",
        tr: "/",
        en: "/"
    },
    features:{
        routeName: "features",
        tr:"/features",
        en:"/features"
    },
    howItWorks:{
        routeName: "how-it-works",
        tr:"/how-it-works",
        en:"/how-it-works"
    },
    faq:{
        routeName: "faq",
        tr:"/faq",
        en:"/faq"
    },
    company:{
        routeName: "company",
        tr:"/company",
        en:"/company"
    },    
    termsOfService:{
        routeName: "terms-of-use",
        tr:"/terms-of-use",
        en:"/terms-of-use" 
    },
    requestDemo:{
        routeName: "demo",
        tr:"/request-demo",
        en:"/request-demo"
    },
    pricingPage:{
        routeName: "pricing",
        tr:"/pricing",
        en:"/pricing"
    },
    contactPage:{
        routeName: "contact",
        tr:"/contact",
        en:"/contact"
    },
    cookiePage:{
        routeName: "cookie-policy",
        tr:"/cookie-policy",
        en:"/cookie-policy"
    },
    contactFormPage:{
        routeName: "contact-form",
        tr:"/contact-form",
        en:"/contact-form"
    },
    notFoundPage:{
        routeName: "404",
        tr:"/404",
        en:"/404"
    }
}

