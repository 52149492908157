import React, { Component } from 'react';

class TextInputComponent extends Component {
    render() {
        const {forgotPass,input, messageText, label,name, disabled, placeholder,disableInput,maxLength, type, err_txt, meta: { submitFailed, error, touched } } = this.props;
        return (
            <div className={`input-item  ${touched && error ? "input-alert" : ""} ${input.value&& input.value.length>0 ? "valid":""}  `}>
                <label htmlFor={name} >{label}</label>
                <input className={`${input.value&& input.value.length>0 ? "valid":""}`} {...input}  name={name} placeholder={placeholder} disabled={disableInput ? disableInput : false} maxLength={maxLength}/>
              
                {
                    messageText &&
                    <p>{messageText}</p>
                }
                <div className="alert-text">
                    <span>{err_txt}</span>
                </div>
            </div>
        )
    }
};

export default TextInputComponent;
