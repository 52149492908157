import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {connect} from "react-redux";
import {FEATURES} from "../../constants/routePaths";
import {scrollToElement} from "../../helper";
import {withRouter} from "react-router-dom";

class DescriptionComponent extends Component {
    featuresRoot=React.createRef();

    componentDidMount(){
        if (this.props.location.pathname === FEATURES.replace(":lang",this.props.activeLang)){
            /**@TODO timeout olmadan yapılabilir mi kontrol edilecek*/
            setTimeout(()=>{
                scrollToElement(this.featuresRoot.current)
            },500);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.location.pathname!==this.props.location.pathname && this.props.location.pathname === FEATURES.replace(":lang",this.props.activeLang)){
            scrollToElement(this.featuresRoot.current);
        }
    }


    render() {
        let {features}=this.props;
        return (
            <div className="description-root" ref={this.featuresRoot}>
                <div className="description-wrapper safe-area">
                    {
                        features &&
                        <div className="description-container ">
                            <div className="top-block">
                                <h3 dangerouslySetInnerHTML={{__html: features.title}}/>
                                <p>{features.desc}</p>
                            </div>
                            <div className="bottom-block  ">
                                {features.frame && features.frame.map((frame, index) =>{
                                    return(
                                        <div key={index} className="frame-item">
                                            <div className="img-item">
                                                <picture>
                                                    <source  srcSet={`${ASSETS}${frame.img.substring(0, frame.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${frame.img.substring(0, frame.img.lastIndexOf("."))}_2x.webp 2x`} />
                                                    <source  srcSet={`${ASSETS}${frame.img} 1x, ${ASSETS}${frame.img.substring(0, frame.img.lastIndexOf("."))}_2x${frame.img.substring(frame.img.lastIndexOf("."))} 2x`} />
                                                    <img src={`${ASSETS}${frame.img}`} alt="{banner.alt}" />
                                                </picture>
                                            </div>
                                            <div className="text-item">
                                                <p>{frame.title}</p>
                                                <span>{frame.desc}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    features: state.homeReducer[state.LangReducer.lang] && state.homeReducer[state.LangReducer.lang].features ? state.homeReducer[state.LangReducer.lang].features : null,

})
export default withRouter(connect(mapStateToProps)(DescriptionComponent));

