import React, { Component } from 'react';
import { ASSETS } from "../../constants/paths";
import { connect } from "react-redux";
import PageService from "../../pageService/pageService";
import DemoFormComponent from '../../components/forms/demoFormComponent';


class RequestDemoFormPage extends Component {

    pageService = null
    constructor(props) {
        super(props);
        this.pageService = new PageService(props.location.pathname);
        this.pageService.getData(props.dispatch);
    }

    render() {
        return (
            <div className="request-demo-root">
                <div className="request-demo-wrapper">
                    <div className="request-demo-form-container">
                        
                        <DemoFormComponent />
                        <div className="image-block">
                            <div className="image-item up">
                                <picture>
                                            <source media="(min-width: 769px;)" srcSet={`${ASSETS}/img/request-image.png 1x, 
                                    ${ASSETS}/img/request-image-2x.png 2x `} type="image/png" />
                                            <source media="(min-width: 769px;)" srcSet={`${ASSETS}/img/request-image.webp 1x, 
                                    ${ASSETS}/img/Articles-Image/request-image-2x.webp 2x `} type="image/webp" />
                                            <img src={`${ASSETS}/img/request-image.png`} alt="Request-Image" />
                                </picture>
                            </div>
                            <div className="image-item">
                                <picture>
                                            <source media="(min-width: 769px;)" srcSet={`${ASSETS}/img/request_img.png 1x, 
                                    ${ASSETS}/img/request_img_2x.png 2x `} type="image/png" />
                                            <source media="(min-width: 769px;)" srcSet={`${ASSETS}/img/request_img.webp 1x, 
                                    ${ASSETS}/img/Articles-Image/request_img_2x.webp 2x `} type="image/webp" />
                                            <img src={`${ASSETS}/img/request_img.png`} alt="request_img" />
                                </picture>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
})


export default (connect(mapStateToProps)(RequestDemoFormPage));