import React, { Component, Fragment } from 'react';
import { RECEIVE_CONTACT_FORM } from "../../constants/actionTypes";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import TextInputComponent from "../formFields/textInputComponent";
import RecaptchaFieldComponent from "../formFields/recaptchaFieldComponent";
import { checkLetter } from "../../helper";
import { addContactForm } from "../../services/webService";
import LoaderComponent from "../loaderComponent";
import RadioInputContactComponent from '../formFields/radioInputContactComponent';
import LoadingComponent from "../../pages/loadingComponent";
import SuccessLightBox from "../layout/lightBox/successLightBox";
import TextAreaInputComponent from "../formFields/textAreaInputComponent"
import RadioCheckboxComponent from "../formFields/radioCheckboxComponent"
import {normalizePhone ,checkTcNo} from "../../helper/index";
import { COOKIE_CONSENT_KEY } from '../../constants/strings';
const reciveContactForm = (payload) => ({
    type: RECEIVE_CONTACT_FORM,
    payload
})
const validate = (values) => {
    const errors = {};
    if (!values || !values.email || values.email === "" || !/^([a-zA-Z0-9!#$%&'*-`{|}~_.]{1}[a-zA-Z0-9!#$%&'*-`{|}~_.]*)(@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6})$/i.test(values.email)) {
        errors.email = "Email giriniz.";
    }
    if (!values || !values.tc || values.tc === "") {
        errors.tc = "Tc Kimlik Numarası giriniz";
    }
    if (values.tc && values.tc.trim() !== "") {
        let pTCKimlik = values.tc;
        if (pTCKimlik.length < 11) {
            errors.tc = "Tc Kimlik Numarası giriniz ";

        } else if (pTCKimlik.substring(0, 1) === "0") {
            errors.tc = "Tc Kimlik Numarası giriniz ";
        } else {
            let toplam1 = parseInt(pTCKimlik[0].toString(), 10) + parseInt(pTCKimlik[2].toString(), 10) + parseInt(pTCKimlik[4].toString(), 10) + parseInt(pTCKimlik[6].toString(), 10) + parseInt(pTCKimlik[8].toString(), 10);
            let toplam2 = parseInt(pTCKimlik[1].toString(), 10) + parseInt(pTCKimlik[3].toString(), 10) + parseInt(pTCKimlik[5].toString(), 10) + parseInt(pTCKimlik[7].toString(), 10);
            let sonuc = ((toplam1 * 7) - toplam2) % 10;
            if (sonuc.toString() !== pTCKimlik[9].toString()) {
                errors.tc = "Tc Kimlik Numarası giriniz ";
            }
            let toplam3 = 0;
            for (let i = 0; i < 10; i++) {
                toplam3 += parseInt(pTCKimlik[i].toString(), 10)
            }
            if ((toplam3 % 10).toString() !== pTCKimlik[10].toString()) {
                errors.tc = "Tc Kimlik Numarası giriniz ";
            }
        }
    }
    if (!values || !values.name || values.name === "") {
        errors.name = "isim Giriniz";
    }
    if (!values || !values.adress || values.adress === "") {
        errors.adress = "Adress Giriniz";
    }
    if (!values || !values.textarea || values.textarea === "") {
        errors.textarea = "Açıklama Giriniz";
    }
    if (!values || !values.response_type || values.response_type === "") {
        errors.response_type = "Lütfen bir seçim yapınız";
    }
    if (!values || !values.company_relation || values.company_relation === "") {
        errors.company_relation = "Lütfen bir seçim yapınız";
    }
    if (!values || !values.lastName || values.lastName === "") {
        errors.lastName = "Soyad Giriniz";
    }
    if (!values.phone || (values.phone && values.phone.length !== 15)) {
        errors.phone = "Telefon numaranızı giriniz ";
    }
    if (!values.handPhone || (values.handPhone && values.handPhone.length !== 15)) {
        errors.handPhone = "Cep Telefon numaranızı giriniz ";
    }
    if (!values.fax || (values.fax && values.fax.length !== 15)) {
        errors.fax = "Fax numaranızı giriniz ";
    }
    
    if (!values.recaptcha_code || values.recaptcha_code.trim().length === 0) {
        errors.recaptcha_code = true
    }

    return errors;
};

class ContactFormComponent extends Component {
    state = {
        isLoading: false,
        isSuccess: false,
        isRecaptcha: false
    }

    componentDidMount(){
        this.script()

        const cookies = document.cookie
            .split(';')
            .map(cookie => cookie.trim().split('='))
            .reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {});
        const permittedCookieValue = cookies.permittedCookies? JSON.parse(cookies.permittedCookies) : null;
        if (permittedCookieValue && permittedCookieValue.length === 4 ) {
            this.setState ({
                isRecaptcha: true
            })
        }
        console.log("cookies", cookies)
        console.log("permittedCookieValue", permittedCookieValue)
        console.log("cookies.permittedCookies", cookies.permittedCookies)
    }

    script = () =>{
        var head = document.querySelector("head");
        var script = document.createElement("script")
        if (this.props.activeLang === "en"){
            script.setAttribute("id","cookie-bundle")
            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=en-US`)
            head.appendChild(script);
        }else{
            script.setAttribute("id","cookie-bundle")
            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=tr-TR`)
            head.appendChild(script);
        }
    }

    submit = (values) => {
        this.setState({
            isLoading: true
        })
        addContactForm(values).then((res) => {
            if (res.data.success) {
                this.setState({
                    isSuccess: res.data.success,
                    isLoading: false

                })
            } else {
                this.setState({
                    isSuccess: false
                })
            }
        })
    }
    render() {
        let { contactForm, activeLang, succses } = this.props;
        let { isLoading, isSuccess } = this.state;

        return (<div className='contact-form-main'>
            {
                (!contactForm) && <LoadingComponent />
            }
            {
                isSuccess == true ?

                    <SuccessLightBox activeLang={activeLang} succses={succses} />
                    :
                    <Form onSubmit={this.submit}
                        validate={validate}
                        render={({ handleSubmit, values }) => (

                            <form onSubmit={handleSubmit}>
                                {contactForm &&
                                    <div className="form-block">
                                        <div className="head-item" >
                                            {contactForm.title ? <h1>{contactForm.title}</h1> : ""}
                                            <div className='form-desc'dangerouslySetInnerHTML={{ __html: contactForm.desc}} ></div>
                                        </div>
                                        <div className="input-head-block">
                                            <Field
                                                name={"name"}
                                                type="text"
                                                label={contactForm.name && contactForm.name}
                                                component={TextInputComponent}
                                                err_txt={contactForm.errText && contactForm.errText}
                                                placeholder={contactForm.name && contactForm.name}
                                                parse={checkLetter}
                                                maxLength={50}

                                            />
                                            <Field
                                                name={"lastName"}
                                                type="text"
                                                label={contactForm.lastName && contactForm.lastName}
                                                component={TextInputComponent}
                                                err_txt={contactForm.errText && contactForm.errText}
                                                placeholder={contactForm.lastName && contactForm.lastName}
                                                parse={checkLetter}
                                                maxLength={50}


                                            />
                                        </div>
                                        <Field
                                            name={"tc"}
                                            type="text"
                                            label={contactForm.tc && contactForm.tc}
                                            component={TextInputComponent}
                                            err_txt={contactForm.errText && contactForm.errText}
                                            placeholder={contactForm.tc && contactForm.tc}
                                            parse={checkTcNo}

                                        />
                                            <Field
                                            name={"adress"}
                                            type="text"
                                            label={contactForm.adress && contactForm.adress}
                                            component={TextInputComponent}
                                            err_txt={contactForm.errText && contactForm.errText}
                                            placeholder={contactForm.adress && contactForm.adress}
                                            maxLength={50}

                                        />
                                
                               
                                        <Field
                                            name={"handPhone"}
                                            type="text"
                                            label={contactForm.handPhone && contactForm.handPhone}
                                            component={TextInputComponent}
                                            err_txt={contactForm.errText && contactForm.errText}
                                            placeholder={contactForm.handPhone && contactForm.handPhone}
                                            parse={normalizePhone}

                                            maxLength={15}
                                        />
                                        <Field
                                            name={"phone"}
                                            type="text"
                                            label={contactForm.phone && contactForm.phone}
                                            component={TextInputComponent}
                                            err_txt={contactForm.errText && contactForm.errText}
                                            placeholder={contactForm.phone && contactForm.phone}
                                            parse={normalizePhone}
                                            maxLength={15}

                                        />
                                        <Field
                                            name={"fax"}
                                            type="text"
                                            label={contactForm.fax_number && contactForm.fax_number}
                                            component={TextInputComponent}
                                            err_txt={contactForm.errText && contactForm.errText}
                                            placeholder={contactForm.fax_number && contactForm.fax_number}
                                            maxLength={15}
                                            parse={normalizePhone}

                                        />
                                            <Field
                                            name={"email"}
                                            type="text"
                                            label={contactForm.email && contactForm.email}
                                            component={TextInputComponent}
                                            err_txt={contactForm.errText && contactForm.errText}
                                            placeholder={contactForm.email && contactForm.email}

                                        />
                                    </div>
                                }
                                {contactForm &&
                                    <div className="form-middle-block contact-us-form">

                                        <Field
                                        name='company_relation'
                                        component={RadioCheckboxComponent}
                                        options={contactForm.company.companyArr}
                                        title={contactForm.company.title}
                                        type="radio"
                                        err_txt={activeLang === "tr"?"Lütfen bir seçim yapınız":"Please Make a choice"}
                                        btn_id={"radio2"}

                                        />
                                        <Field
                                            name='textarea'
                                            component={TextAreaInputComponent}
                                            placeholder={contactForm.request}
                                            err_txt={activeLang==="tr"?"Açıklama Giriniz":"Enter Description"}
                                        />
                                              <Field
                                        name='response_type'
                                        component={RadioCheckboxComponent}
                                        options={contactForm.radioArr}
                                        type="radio"
                                        err_txt={activeLang === "tr"?"Lütfen bir seçim yapınız":"Please Make a choice"}
                                        btn_id={"radio1"}


                                        />
                              
                                             {contactForm &&
                                                
                                                <Fragment>
                                                    {
                                                        this.state.isRecaptcha ?
                                                        <Field
                                                            name="recaptcha_code"
                                                            component={RecaptchaFieldComponent}
                                                            reCaptchaErr={contactForm.reCaptchaErr && contactForm.reCaptchaErr}
                                                            lang={activeLang ? activeLang : "tr"}

                                                        />
                                                        :
                                                        <div className='sets-cookie-text contact'>
                                                            {
                                                                activeLang === "tr" ?
                                                                <p>Form gönderebilmek için çerez ayarlarına izin vermeniz gerekmektedir. <span onClick={() => window.toggleCookieModal()}>Buraya</span> tıklayarak izin verebilirsiniz.</p>
                                                                :
                                                                <p>To be able to submit the form, you need to allow cookies settings. You can grant permission by clicking <span onClick={() => window.toggleCookieModal()}>here.</span></p>
                                                            }
                                                        </div>
                                                    }
                                                </Fragment>
                                            }
                                        <div className='clText' dangerouslySetInnerHTML={{ __html: contactForm.clText}} ></div>
                                        <button type="submit" className='btn-item'>
                                            {contactForm.btnText ? <span>{contactForm.btnText}</span> : ""}
                                        </button>
                                    </div>
                                }
                                {
                                    contactForm&& 
                                              <div className='form-desc'dangerouslySetInnerHTML={{ __html: contactForm.finDesc}} ></div>
                                }

                                {
                                    isLoading == true ?
                                        <LoaderComponent />
                                        :
                                        <></>
                                }
                            </form>
                        )}>


                    </Form>
            }
  {/* {
                  contactForm &&
                                    contactForm.table.map((obj, index) => {
                                        return (

                                            <div className="table-block" key={index}>
                                                <span>
                                                    {obj.title}

                                                </span>
                                                <table>

                                                    <tr>
                                                        {
                                                            obj.column.map((objCol, indexCol) => {
                                                                return (
                                                                    <th key={indexCol} >{objCol.text}</th>

                                                                )
                                                            })
                                                        }

                                                    </tr>

                                                    {
                                                        obj.rows&&
                                                        obj.rows.map((objRow, indexRow) => {
                                                            return (

                                                                <tr key={indexRow}>
                                                                    {
                                                                        objRow&&
                                                                        objRow.texts.map((objTxt,indexTxt) => {
                                                                            return (
                                                                                <td key={indexTxt} >{objTxt.text}</td>

                                                                            )
                                                                        })
                                                                    }


                                                                </tr>

                                                            )
                                                        })
                                                    }



                                                </table>
                                            </div>
                                        )
                                    })
                                } */}


        </div>);
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    contactForm: state.contactReducer[state.LangReducer.lang] ? state.contactReducer[state.LangReducer.lang].contactForm : null,
    succses: state.contactReducer[state.LangReducer.lang] ? state.contactReducer[state.LangReducer.lang].succses : null,
   


})
export default connect(mapStateToProps)(ContactFormComponent);
