import React, { Component } from 'react'
import { connect } from 'react-redux';

class CookieAcceptLightBox extends Component {

    container = React.createRef();
    root = React.createRef();
 
    componentDidMount() {
       if(typeof window !==undefined){
          document.body.classList.add("no-scroll")
       }
       if(typeof window !== undefined){
          this.root.current.addEventListener("click", this.clickOutSide)
       }
    }
 
    clickOutSide =(e)=> {
       if(this.container && this.container.current && !this.container.current.contains(e.target)) {
        this.props.toggleCookieLb()
       }
    }
 
    componentWillUnmount() {
       if(typeof window!==undefined) {
          document.body.classList.remove("no-scroll")
       }
       if(typeof window !== undefined){
          this.root.current.removeEventListener("click", this.clickOutSide)
       }
    }

  render() {
    let {toggleCookieLb, acceptLb} = this.props
    return (
      <div className='cookie-accept-lb-root' ref={this.root}>
         {
            acceptLb &&
            <div className='cookie-lb-wrapper'>
            <div className='lb-container' ref={this.container}>
                <div className='close-btn-item' onClick={() => toggleCookieLb()}>

                </div>
                <div className='text-block'>
                    <span>{acceptLb.title}</span>
                    {
                        this.props.activeLang === "en" ? 
                        <p>To view this type of content, you need to allow cookies in the <span>“Functional Cookies”</span> category.</p>
                        :
                        <p>Bu tip içerikleri görüntülemek için <span>“İşlevsellik Çerezleri”</span> kategorisindeki çerezlere izin vermeniz gerekmektedir.</p>
                    }
                </div>
                <div className='lb-btn-block'>
                    <div className='lb-btn-item' onClick={() => toggleCookieLb()}>
                        <span>{acceptLb.rejectBtnText}</span>
                    </div>
                    <div className='lb-btn-item' onClick={() => window.changeUserPermitWithArr(["Analytics"])}>
                        <span>{acceptLb.allowBtnText}</span>
                    </div>
                </div>
            </div>
        </div>
         }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
   activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
   acceptLb: state.contactReducer[state.LangReducer.lang] ? state.contactReducer[state.LangReducer.lang].cookieAcceptLb : null,
})

export default connect(mapStateToProps) (CookieAcceptLightBox)