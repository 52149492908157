import React, { Component } from 'react';
import { HOME, routePaths, REQUEST_DEMO } from "../constants/routePaths";
import HomePage from "../pages/homePage";
import TermsOfServicePage from "../pages/Privacy/termsOfServicePage";
import { Redirect, Route, withRouter, Switch } from "react-router-dom";
import Paths from "../helper/path"
import { EN_ROUTE, ENLANG, TR_ROUTE, TRLANG } from "../constants/path";
import { connect } from "react-redux";
import { changeActiveLangAction } from "../actions";
import HeaderComponent from "./layout/headerComponent";
import FooterComponent from "./layout/footerComponent";
import RequestDemoPage from "../pages/Demo/requestDemoFormPage";
import RequestDemoFormPage from "../pages/Demo/requestDemoFormPage";
import ContactPageComponent from "../pages/contactPageComponent";
import ErorrComponent from '../pages/erorrComponent';
import LoadingComponent from '../pages/loadingComponent';
import PricingPage from '../pages/pricingPage';
import CookieTextPage from '../pages/cookie/cookieTextPage';
import ContactFormPage from '../pages/Demo/contactFormPage';


class RoutesComponent extends Component {
    paths = null;
    state = {
        language: typeof window !== "undefined" && navigator?navigator.language.split("-")[0]:"tr",
        //language: "tr",
        loading: false
    }

    constructor(props) {
        super(props);
        this.checkLang(props);

    }
    
  
    


    componentWillReceiveProps(nextProps) {

        if (this.props.location.pathname !== nextProps.location.pathname) {
            let locationArr = nextProps.location.pathname.split("/");

            this.pathGenerator(locationArr[1]);
            this.props.dispatch(changeActiveLangAction(locationArr[1]));
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
       
        if (typeof window !== "undefined" && prevProps.location.pathname !== this.props.location.pathname) {
            if(this.props.location.pathname == this.paths.features || this.props.location.pathname== this.paths.howItWorks || this.props.location.pathname ==this.paths.faq  ){
            }else{
                window.scrollTo(0, 0);

            }
     
        }
   
    }
  
    pathGenerator = (lang) => {
        this.paths = new Paths(lang, routePaths);
    }
    checkLang = (props) => {
        let locationArr = this.props.location.pathname.split("/");
        if (locationArr[1] === ENLANG) {
            this.pathGenerator(ENLANG);
            this.props.dispatch(changeActiveLangAction(ENLANG));

        } else if (locationArr[1] === TRLANG) {
            this.pathGenerator(TRLANG);
            this.props.dispatch(changeActiveLangAction(TRLANG));

        }
        else if (locationArr[1] === "") {
            let lng = this.state.language
            this.props.dispatch(changeActiveLangAction(lng));
            if (lng === ENLANG) {
                this.props.history.push(EN_ROUTE);
                this.pathGenerator(ENLANG);
            } else if (lng === TRLANG) {
                this.props.history.push(TR_ROUTE);
                this.pathGenerator(TRLANG);
            }
        } else if (!locationArr[1] || locationArr[1].length === 0) {
            this.props.dispatch(changeActiveLangAction(TRLANG));
            this.props.history.push(TR_ROUTE);
        } else {
            locationArr.splice(1, 0, TRLANG);
            this.pathGenerator(TRLANG);
            this.props.history.push("/tr/404");
        }
    };

    render() {
        return (
            <main>
               

                        
                       
                            <Switch>
                                <Route component={HeaderComponent} />
                            </Switch>
                            <Switch>
                                <Route exact path={this.paths.home} component={HomePage}  />
                                <Route exact path={this.paths.features} component={HomePage} />
                                <Route exact path={this.paths.howItWorks} component={HomePage} />
                                <Route exact path={this.paths.faq} component={HomePage} />
                                <Route exact path={this.paths.termsOfService} component={TermsOfServicePage} />
                                <Route exact path={this.paths.requestDemo} component={RequestDemoFormPage} />
                                <Route exact path={this.paths.contactPage} component={ContactPageComponent} />
                                <Route exact path={this.paths.pricingPage} component={PricingPage} />
                                <Route exact path={this.paths.cookiePage} component={CookieTextPage} />
                                <Route exact path={this.paths.contactFormPage} component={ContactFormPage} />


                                <Route exact path={this.paths.notFoundPage} component={ErorrComponent} />

                            </Switch>
                            <Switch>
                                <Route component={FooterComponent} />
                            </Switch>
               
                
            </main>



        );
    }
}

const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
});
export default withRouter(connect(mapStateToProps)(RoutesComponent));
