import React, { Component } from 'react';
import{HOME_PAGE} from "../../../constants/routePaths";
import { connect } from 'react-redux';
import {Link} from "react-router-dom"
class SuccessLightBox extends Component {

    render() {
        let {activeLang,succses} = this.props
        return (
            <div className="success-message-root" >
                {
                    succses &&
                    <div className="message-wrapper">
                    <div className="message-container">
                        <div className="img-block">
                            <i>
                            <svg width="44" height="38" viewBox="0 0 44 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M4.70523 15.6407C8.96236 19.2946 15.1689 24.8622 15.1689 24.8622C15.1689 24.8622 29.4504 8.07987 42.9598 0L44 2.81706C39.0887 5.92248 24.2505 22.9355 18.4354 38C18.436 38 2.95065 22.7418 0 19.2645L4.70522 15.6406L4.70523 15.6407Z" fill="#7FD062"/>
</svg>

                            </i>
                        </div>
                        <div className="text-block">
                            <h2>{succses.title}</h2>
                            <p>{succses.desc}</p>
                        </div>
                        <Link to={HOME_PAGE.replace(":lang",activeLang)} className="btn-item">
                        {succses.btn_txt}
                        </Link>
                    </div>
                </div>
                }
               
            </div>
        );
    }

  
}

export default connect ()(SuccessLightBox);
