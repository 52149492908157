export default class Paths{
    home = undefined;
    howItWorks=undefined;
    features= undefined;
    faq=undefined;
    termsOfService=undefined;
    requestDemo=undefined;
    contactPage=undefined;
    pricingPage=undefined;
    cookiePage=undefined;
    contactFormPage=undefined;
    constructor(lang, data) {
        let arr = Object.keys(data);
        arr.forEach((val, index) => {
            if (this.hasOwnProperty(val)) {
                this[val] = data[lang + 'Lang'] + (data[val][lang] !== "/" ? data[val][lang] : "");
            }
        });
    }
}