import React, { Component } from 'react';
import PageService from "../pageService/pageService";
import { connect } from "react-redux";
import LoadingComponent from '../pages/loadingComponent';
import { COOKIE_CONSENT_KEY } from '../constants/strings';

class ContactPageComponent extends Component {



    pageService = null
    constructor(props) {
        super(props);
        this.pageService = new PageService(props.location.pathname);
        this.pageService.getData(props.dispatch);
    }

    componentDidMount(){
        this.script()
    }

    script = () =>{
        var head = document.querySelector("head");
        var script = document.createElement("script")
        if (this.props.activeLang === "en"){
            script.setAttribute("id","cookie-bundle")
            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=en-US`)
            head.appendChild(script);
        }else{
            script.setAttribute("id","cookie-bundle")
            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=tr-TR`)
            head.appendChild(script);
        }
    }

    render() {
        let { contact, activeLang } = this.props

        return (
            <div>
                
                    {
                        (!contact) && <LoadingComponent />
                    }
                    {
                        contact &&

                        <div className="contact-root">


                            <div className="contact-us-container">

                                <div className="contact-content-container">
                                    <div className="contact-us-block">
                                        <h3>{contact.title}</h3>
                                        <p>{contact.desc}</p>

                                    </div>
                                    <div className="contact-us-block">
                                        <span>{contact.adress_title + ":"}</span>
                                        <p>{contact.address}</p>
                                    </div>
                                    <div className="contact-us-block">
                                        <span>{contact.mail_title + ":"}</span>
                                        <a href="mailto:info@cookiesuit.com">{contact.mail}</a>
                                    </div>
                                </div>



                            </div>

                            {/* <div className="map-container">
                                <div className="map-block">

                                </div>


                            </div> */}
                        </div>
                    }




            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    contact: state.contactReducer[state.LangReducer.lang] && state.contactReducer[state.LangReducer.lang].contact ? state.contactReducer[state.LangReducer.lang].contact : null,


})
export default (connect(mapStateToProps)(ContactPageComponent));
