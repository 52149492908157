import React, { Component } from 'react';
import PageService from '../pageService/pageService';
import { connect } from 'react-redux';
import {getPricing} from "../services/webService";
import LoadingComponent from './loadingComponent';
import { NavLink } from 'react-router-dom';
import {REQUEST_DEMO} from "../constants/routePaths";
import { COOKIE_CONSENT_KEY } from '../constants/strings';
class PricingPage extends Component {
    pageService = null
    constructor(props) {
        super(props);
        this.pageService = new PageService(props.location.pathname);
        this.pageService.getData(props.dispatch);
    }
    componentDidMount(){
        this.script()
    }

    script = () =>{
        var head = document.querySelector("head");
        var script = document.createElement("script")
        if (this.props.activeLang === "en"){
            script.setAttribute("id","cookie-bundle")
            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=en-US`)
            head.appendChild(script);
        }else{
            script.setAttribute("id","cookie-bundle")
            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=tr-TR`)
            head.appendChild(script);
        }
    }
    render() {
        let {pricing,activeLang} = this.props;
        return (
           
            <div className='pricing-root'>
                {
                        (!pricing) && <LoadingComponent/>
                    }
                {
                    pricing &&
                    <div className="pricing-wrapper safe-area">
                    <div className="pricing-container">
                        <div className="pricing-head-item">
                            <h1>{pricing.title}</h1>
                        </div>
                        <div className="pricing-card-block">
                            {
                                
                                pricing.card.map((obj,index)=>{
                                    return(
                                        <div className="card-item" key={index}>
                                            <div className="crd-head">
                                            <div className="text-block">
                                            <h2>{obj.title}</h2>
                                            <p>{obj.desc}</p>
                                        </div>
                                        <div className="price-block">
                                            <h3>{obj.price}</h3>
                                            <ul>
                                                {
                                                    obj.props.map((obj,index)=>{
                                                        return(
                                                            <li key={index}>{obj.name}</li>
                                                            
                                                        )
                                                    })
                                                }
                                            
                                            </ul>
                                        </div>
                                            </div>
                                  
                                        <div className="btn-block">
                                            <NavLink to={REQUEST_DEMO.replace(":lang",activeLang)} className="btn-item white-blue ">
                                                <span>{obj.btnText}</span>
                                            </NavLink>
                                            <div className="desc-block">
                                                <p>{obj.cardDesc}</p>
                                            </div>
                                        </div>
                          
                                    </div>
                                    )
                                })
                            }

                           
                           
                        </div>
                    </div>
                </div>
                }
              
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    pricing: state.PricingReducer[state.LangReducer.lang] && state.PricingReducer[state.LangReducer.lang].pricing ? state.PricingReducer[state.LangReducer.lang].pricing : null,


})
export default connect(mapStateToProps) (PricingPage);
