import React, { Component } from "react"
import PageService from "../../pageService/pageService";
import {connect} from "react-redux";
import { COOKIE_CONSENT_KEY } from '../../constants/strings';


class TermsOfServicePage extends Component {

    pageService=null
    constructor(props) {
        super(props);
        this.pageService = new PageService(props.location.pathname);
        this.pageService.getData(props.dispatch);
    }
    componentDidMount(){
        this.script()
    }

    script = () =>{
        var head = document.querySelector("head");
        var script = document.createElement("script")
        if (this.props.activeLang === "en"){
            script.setAttribute("id","cookie-bundle")

            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=en-US`)
            head.appendChild(script);
        }else{
            script.setAttribute("id","cookie-bundle")

            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=tr-TR`)
            head.appendChild(script);
        }
    }
    render() {
        let {banner,middleText,service, account,agreementTerm,refund} = this.props
        return (
            <div className="terms-of-service-root">
                <div className="banner-root">
                        <div className="banner-wrapper">
                            <div className="banner-container">
                                    <div className="title-block">
                                        {
                                            banner &&
                                                <div className="title-item">
                                                    <h1>{banner.title}</h1>
                                                </div>
                                        }
                                    </div>
                            </div>
                        </div>
                </div>

                <div className="terms-of-service-wrapper">
                  <div className="text-container">
                      <div className="text-block">
                      {
                              middleText &&
                              <div className="head-item">
                                  <h3>{middleText.title}</h3>
                              </div>
                          }
                          {
                              middleText &&
                              <div className="text-item">
                                  <p>{middleText.desc}</p>
                              </div>
                          }

                      </div>
                        <div className="text-block">
                            {
                                service &&
                                <div className="head-item">
                                    <h3>{service.title}</h3>
                                </div>
                            }
                            {
                                service &&
                                <div className="text-item">
                                    <p>{service.desc}</p>
                                </div>
                            }


                      </div>

                        <div className="text-block">
                          {
                              account &&
                              <div className="head-item">
                                  <h3>{account.title}</h3>
                              </div>

                          }
                          {
                              account &&
                              <div className="text-item">
                                  <p>{account.desc}</p>
                              </div>
                          }

                      </div>
                        <div className="text-block">
                            {
                                agreementTerm &&
                                <div className="head-item">
                                    <h3>{agreementTerm.title}</h3>
                                </div>
                            }
                            {
                                agreementTerm &&
                                <div className="text-item">
                                    <p>{agreementTerm.desc}</p>
                                </div>
                            }

                        </div>
       
                  </div>
                </div>
            </div>

        )
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    banner: state.PrivacyReducer[state.LangReducer.lang] && state.PrivacyReducer[state.LangReducer.lang].banner ? state.PrivacyReducer[state.LangReducer.lang].banner : null,
    middleText: state.PrivacyReducer[state.LangReducer.lang] && state.PrivacyReducer[state.LangReducer.lang].middleText ? state.PrivacyReducer[state.LangReducer.lang].middleText : null,
    service: state.PrivacyReducer[state.LangReducer.lang] && state.PrivacyReducer[state.LangReducer.lang].service ? state.PrivacyReducer[state.LangReducer.lang].service : null,
    account: state.PrivacyReducer[state.LangReducer.lang] && state.PrivacyReducer[state.LangReducer.lang].account ? state.PrivacyReducer[state.LangReducer.lang].account : null,
    agreementTerm: state.PrivacyReducer[state.LangReducer.lang] && state.PrivacyReducer[state.LangReducer.lang].agreementTerm ? state.PrivacyReducer[state.LangReducer.lang].agreementTerm : null,
    
})

export default (connect(mapStateToProps) (TermsOfServicePage));