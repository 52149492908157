import {RECEIVE_CONTACT,RECEIVE_FORM} from "../constants/actionTypes";


const initialState ={};

const contactReducer = (state = initialState, action )=>{
    switch (action.type){
        case RECEIVE_CONTACT :
            return{
                ...state,
                ...action.payload
            }
        case RECEIVE_FORM:
            return {
                    ...state,
                    ...action.payload
                
            }
        default:
            return state
    }

}
export default contactReducer;