import React, {Component} from 'react';

class RadioInputComponent extends Component {
    render() {
        let {input,options,inputText,errText, meta: { submitFailed, error, touched },btnid} = this.props;
        return(
           
            <div  className={`checkbox-block  ${touched && error  ? "alert" : ""}   `}>


                <div className="checkbox-item" >
                    <input value={input.value} onChange={input.onChange} type="checkbox" id={"overlay-input" }/>
                    <label htmlFor="overlay-input" className="overlay-button">
                        <span></span>
                        <p className="checkbox-text-item">{inputText}
                        </p>
                    </label>
                </div>
            </div>
        )
    }
}

export default RadioInputComponent;
