import React, { Component, Fragment } from 'react';
import { Field, Form } from "react-final-form";
import TextInputComponent from "../formFields/textInputComponent";
import RecaptchaFieldComponent from "../formFields/recaptchaFieldComponent";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoaderComponent from "../loaderComponent";
import { REQUEST_DEMO } from "../../constants/routePaths";
import { REQUEST_FORM } from "../../constants/actionTypes";
import LoadingComponent from "../../pages/loadingComponent";
import { submitForm } from "../../services/webService";
import RadioInputComponent from '../formFields/radioInputComponent';
import { checkLetter } from "../../helper";
import SuccessLightBox from "../layout/lightBox/successLightBox";
import { COOKIE_CONSENT_KEY } from '../../constants/strings';
import CookieFormNotice from './cookieFormNotice';
const reciveRequestForm = (payload) => ({
    type: REQUEST_FORM,
    payload
})

const validate = (values) => {
    const errors = {};
    if (!values || !values.email || values.email === "" || !/^([a-zA-Z0-9!#$%&'*-`{|}~_.]{1}[a-zA-Z0-9!#$%&'*-`{|}~_.]*)(@[0-9a-zA-Z]+([-.][0-9a-zA-Z]+)*([0-9a-zA-Z]*[.])[a-zA-Z]{2,6})$/i.test(values.email)) {
        errors.email = "Email giriniz.";
    }

    if (!values || !values.name || values.name === "") {
        errors.name = "isim Giriniz";
    }

    if (!values || !values.lastName || values.lastName === "") {
        errors.lastName = "Soyad Giriniz";
    }
    if (!values || !values.terms_and_condition || values.terms_and_condition === "") {
        errors.terms_and_condition = "Bu Alanı Boş Bırakamazsınız";
    }

    if (!values || !values.job || values.job === "") {
        errors.job = "Başlık Giriniz";
    }
    if (!values || !values.company || values.company === "") {
        errors.company = "Company Giriniz";
    }
    if (!values.recaptcha_code || values.recaptcha_code.trim().length === 0) {
        errors.recaptcha_code = true
    }

    return errors;
};


class DemoFormComponent extends Component {
    state = {
        isLoading: false,
        isSuccess: false,
        isCookieNoticeLb: true,
        isRecaptcha: false,
    }

    formContainer = React.createRef()

    componentDidMount(value) {
        this.script()

        submitForm({ value }).then((res) => {
            console.log(res.data.success)
        })

        if(this.formContainer && this.formContainer.current) {
            this.formContainer.current.style.padding = null
        }

        this.permittedCookie()
    }

    permittedCookie = () => {
        const cookies = document.cookie
            .split(';')
            .map(cookie => cookie.trim().split('='))
            .reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {});
        const permittedCookieValue = cookies.permittedCookies? JSON.parse(cookies.permittedCookies) : null;
        if (permittedCookieValue && permittedCookieValue.includes("Analytics") ) {
            this.setState ({
                isCookieNoticeLb: false,
                isRecaptcha: true
            })
            if(this.formContainer && this.formContainer.current) {
                this.formContainer.current.style.padding = 0
            }
        }
    }


    script = () =>{
        var head = document.querySelector("head");
        var script = document.createElement("script")
        if (this.props.activeLang === "en"){
            script.setAttribute("id","cookie-bundle")
            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=en-US`)
            head.appendChild(script);
        }else{
            script.setAttribute("id","cookie-bundle")
            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=tr-TR`)
            head.appendChild(script);
        }
        
    }
    submit = (values) => {
        this.setState({
            isLoading: true
        })
        submitForm(values).then((res) => {
            if (res.data.success) {
                this.setState({
                    isSuccess: res.data.success,
                    isLoading: false

                })
            } else {
                this.setState({
                    isSuccess: false
                })
            }
        })
    }
    render() {
        let { isLoading, isSuccess, isCookieNoticeLb, isRecaptcha } = this.state;
        let { form, activeLang, succses } = this.props;
        return (<div className='form-container' ref={this.formContainer}>
                      {
                        (!form) && <LoadingComponent />
                    }
            {
                isSuccess == true ?

                    <SuccessLightBox activeLang={activeLang} succses={succses} />
                    :
                    <Form onSubmit={this.submit}
                        validate={validate}
                        render={({ handleSubmit }) => (

                            <form onSubmit={handleSubmit} >
                                {form &&
                                    <div className="form-block">
                                        <div className="head-item">
                                            {form.title ? <h1>{form.title}</h1> : ""}
                                            <p className='form-desc' >{form.desc}</p>
                                        </div>
                                        <div className="input-head-block">
                                            <Field
                                                name={"name"}
                                                type="text"
                                                label={form.placeholder && form.placeholder.name}
                                                component={TextInputComponent}
                                                err_txt={form.errText && form.errText}
                                                placeholder={form.placeholder && form.placeholder.name}
                                                parse={checkLetter}
                                                maxLength={50}

                                            />
                                            <Field
                                                name={"lastName"}
                                                type="text"
                                                label={form.placeholder && form.placeholder.lastName}
                                                component={TextInputComponent}
                                                err_txt={form.errText && form.errText}
                                                placeholder={form.placeholder && form.placeholder.lastName}
                                                parse={checkLetter}
                                                maxLength={50}


                                            />
                                        </div>

                                        <Field
                                            name={"email"}
                                            type="text"
                                            label={form.placeholder && form.placeholder.email}
                                            component={TextInputComponent}
                                            err_txt={form.errText && form.errText}
                                            placeholder={form.placeholder && form.placeholder.email}

                                        />
                                        <Field
                                            name={"job"}
                                            type="text"
                                            label={form.placeholder && form.placeholder.job}
                                            component={TextInputComponent}
                                            err_txt={form.errText && form.errText}
                                            placeholder={form.placeholder && form.placeholder.job}

                                        />
                                        <Field
                                            name={"company"}
                                            type="text"
                                            label={form.placeholder && form.placeholder.company}
                                            component={TextInputComponent}
                                            err_txt={form.errText && form.errText}
                                            placeholder={form.placeholder && form.placeholder.company}
                                            maxLength={50}

                                        />


                                    </div>
                                }
                                {form &&
                                    <div className="form-middle-block">

                                        <Field
                                            name='terms_and_condition'
                                            component={RadioInputComponent}
                                            inputText={form.radioButton && form.radioButton}
                                            err_txt={form.errText && form.errText}

                                        />
                                        <button type="submit" className='btn-item'>
                                            {form.btnText ? <span>{form.btnText}</span> : ""}
                                        </button>
                                    </div>
                                }

                                {form && isRecaptcha &&
                                    <Field
                                    name="recaptcha_code"
                                    component={RecaptchaFieldComponent}
                                    reCaptchaErr={form.reCaptchaErr && form.reCaptchaErr}
                                    lang={activeLang ? activeLang : "tr"}
                                    />
                                }

                                {
                                    form &&
                                    <div className='clText' dangerouslySetInnerHTML={{ __html: form.clText}} ></div>
                                }

                                {
                                    isLoading == true ?
                                        <LoaderComponent />
                                        :
                                        <></>
                                }
                            </form>
                        )}>


                    </Form>
            }
            {
                isCookieNoticeLb &&
                <CookieFormNotice/>
            }


        </div>);
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    form: state.contactReducer[state.LangReducer.lang] ? state.contactReducer[state.LangReducer.lang].form : null,
    succses: state.contactReducer[state.LangReducer.lang] ? state.contactReducer[state.LangReducer.lang].succses : null

})
export default connect(mapStateToProps)(DemoFormComponent);