import {RECEIVE_COOKIE} from "../constants/actionTypes";


const initialState ={};

const cookiePolicyReducer = (state = initialState, action )=>{
    switch (action.type){
        case RECEIVE_COOKIE :
            return{
                ...state,
                ...action.payload
            }
        default:
            return state
    }

}
export default cookiePolicyReducer;