import{combineReducers} from 'redux';
import homeReducer from '../reducers/homeReducer';
import contactReducer from './contactReducer';
import LangReducer from './langReducer';
import LayoutReducer from './layoutReducer';
import PrivacyReducer from "./privacyReducer";
import PricingReducer from "./pricingReducer";
import cookiePolicyReducer from "./cookiePolicyReducer";
const combineRed = combineReducers({
    LayoutReducer,
    homeReducer,
    LangReducer,
    PrivacyReducer,
    PricingReducer,
    cookiePolicyReducer,
    contactReducer
});

export default combineRed
