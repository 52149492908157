import {RECEIVE_PRIVACY} from "../constants/actionTypes";


const initialState ={};

const PrivacyReducer = (state = initialState, action )=>{
    switch (action.type){
        case RECEIVE_PRIVACY :
            return{
                ...state,
                ...action.payload
            }
        default:
            return state
    }

}
export default PrivacyReducer;