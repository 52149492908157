import React, { Component } from 'react';

class LoadingComponent extends Component {
    render() {
        return (
            <div className='load-root'>
                      <div className="lds-ring">
                        <div className="lds-item one" ></div>
                        <div className="lds-item two"></div>
                        <div className="lds-item three" ></div>
                        <div className="lds-item four"></div>
                        </div>
            </div>
        );
    }
}

export default LoadingComponent;
