import {RECEIVE_PRICING} from "../constants/actionTypes";


const initialState ={};

const PricingReducer = (state = initialState, action )=>{
    switch (action.type){
        case RECEIVE_PRICING :
            return{
                ...state,
                ...action.payload
            }
        default:
            return state
    }

}
export default PricingReducer;