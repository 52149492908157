export const RECEIVE_HOME = "RECEIVE_HOME";
export const RECEIVE_LAYOUT = "RECEIVE_LAYOUT";
export const RECEIVE_PRIVACY = "RECEIVE_PRIVACY";
export const CHANGE_LANG = "CHANGE_LANG";
export const REQUEST_FORM = "REQUEST_FORM";
export const RECEIVE_CONTACT = "RECEIVE_CONTACT";
export const RECEIVE_FORM = "RECEIVE_FORM";
export const RECEIVE_PRICING = "RECEIVE_PRICING"
export const RECEIVE_COOKIE = "RECEIVE_COOKIE"
export const RECEIVE_CONTACT_FORM = "RECEIVE_CONTACT_FORM";

