import React, { Component } from 'react';

class TextAreaInputComponent extends Component {
    render() {
        const {forgotPass,input,messageText, label, disabled, placeholder, type, err_txt, meta: { submitFailed, error, touched } } = this.props;
        return (
           
            <div  className={`textarea-block  ${touched && error ? "input-alert" : ""}`}>
                <textarea placeholder={placeholder} rows="4" {...input} >
                
                </textarea>
                <div className="alert-text">
                        <span>{err_txt}</span>
                    </div>
            </div>
               
             
           

        )
    }
};

export default TextAreaInputComponent;
