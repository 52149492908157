import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BannerComponent from "../components/home/bannerComponent";
import DescriptionComponent from "../components/home/descriptionComponent";
import CompanyComponent from "../components/home/companyComponent";
import FaqComponent from "../components/home/faqComponent";
import HowItWorksComponent from "../components/home/howItWorksComponent";
import FooterComponent from "../components/layout/footerComponent";
import HeaderComponent from "../components/layout/headerComponent";
import { scrollToElement } from "../helper";
import PageService from "../pageService/pageService";
import LoadingComponent from "./loadingComponent";
import { COOKIE_CONSENT_KEY } from '../constants/strings';

class HomePage extends Component {
    componentDidMount(){
        this.script()
    }
    script = () =>{
        var head = document.querySelector("head");
        var script = document.createElement("script")
        if (this.props.activeLang === "en"){
            script.setAttribute("id","cookie-bundle")
            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=en-US`)
            head.appendChild(script);
    
        }else{
            script.setAttribute("id","cookie-bundle")
            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=tr-TR`)
            head.appendChild(script);
        }
    }

    bannerComponent = React.createRef();
    descriptionComponent = React.createRef();
    howItWorksComponent = React.createRef();
    faqComponent = React.createRef();
    companyComponent = React.createRef();
    pageService = null;
    constructor(props) {
        super(props);
        this.pageService = new PageService(props.location.pathname);
        this.pageService.getData(props.dispatch);
    }

    render() {
        return (
            <div>
                {
                      (!this.props.banner) && <LoadingComponent />
                }
                <main className="home-root">
                    {
                        this.props.banner&&
                        <BannerComponent/>
                    }
                    <div className="page-section" ref={this.descriptionComponent}>
                        {
                            this.props.features &&
                            <DescriptionComponent/>
                        }

                    </div>
                    <div className="page-section" ref={this.howItWorksComponent}>
                        {
                            this.props.howItWorks &&
                            <HowItWorksComponent/>
                        }

                    </div>
                    <div className="page-section" ref={this.faqComponent}>
                        {
                            this.props.faq &&
                            <FaqComponent />
                        }

                    </div>
                    {/* <div className="page-section" ref={this.companyComponent}>
                        {
                            this.props.company &&
                            <CompanyComponent/>
                        }
                    </div>     */}
                </main>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    faq: state.homeReducer[state.LangReducer.lang] && state.homeReducer[state.LangReducer.lang].faq ? state.homeReducer[state.LangReducer.lang].faq : null,
    company: state.homeReducer[state.LangReducer.lang] && state.homeReducer[state.LangReducer.lang].company ? state.homeReducer[state.LangReducer.lang].company : null,
    howItWorks: state.homeReducer[state.LangReducer.lang] && state.homeReducer[state.LangReducer.lang].howItWorks ? state.homeReducer[state.LangReducer.lang].howItWorks : null,
    features: state.homeReducer[state.LangReducer.lang] && state.homeReducer[state.LangReducer.lang].features ? state.homeReducer[state.LangReducer.lang].features : null,
    banner: state.homeReducer[state.LangReducer.lang] && state.homeReducer[state.LangReducer.lang].banner ? state.homeReducer[state.LangReducer.lang].banner : null
})

export default withRouter(connect(mapStateToProps) (HomePage));
