import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { COMPANY } from '../../constants/routePaths';
import {scrollToElement} from "../../helper";
import {ASSETS} from "../../constants/paths";

class CompanyComponent extends Component {
    companyRoot=React.createRef();
    state = {
       isListen: "",
       isOpen: true
    }
    componentDidMount() {
        console.log("this.props.location.pathname----",this.props.location.pathname,"-----",COMPANY.replace(":lang",this.props.activeLang),"activeLang",this.props.activeLang);
        if(this.props.location.pathname === COMPANY.replace(":lang",this.props.activeLang)){
           /**@TODO timeout olmadan yapılabilir mi kontrol edilecek*/
           setTimeout(() => {
              scrollToElement(this.companyRoot.current);
           }, 500);
        }
     }
  
     componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.location.pathname!==this.props.location.pathname && this.props.location.pathname === COMPANY.replace(":lang",this.props.activeLang)){
           scrollToElement(this.companyRoot.current);
        }
     }
  
     toggleListMenu = (event) => {
        if (event === "general") {
           this.setState({
              isListen: event,
              isOpen: false
           })
  
        } else {
           this.setState({
              isListen: event,
              isOpen: true
           })
  
        }
     }

    render() {
        let {company}= this.props
        return (
            <div className="company-root" ref={this.companyRoot}>
                <div className="company-wrapper safe-area">
                <div className="company-container">
                    <div className="text-block">
                        <div className="title-item">
                            <h3 dangerouslySetInnerHTML={{__html: company.title}}/>
                        </div>
                        <div className="text-item">
                            <span>{company.text}</span>
                        </div>
                    </div>
                    <div className="img-block">
                        {
                            company.images && company.images.map((frame, index) =>{
                                return(
                                    <div key={index} className="frame-item">
                                        <div className="img-item">
                                            <picture>
                                                <source  srcSet={`${ASSETS}/${frame.img.substring(0, frame.img.lastIndexOf("."))}.webp 1x, ${ASSETS}/${frame.img.substring(0, frame.img.lastIndexOf("."))}_2x.webp 2x`} />
                                                <source  srcSet={`${ASSETS}/${frame.img} 1x, ${ASSETS}/${frame.img.substring(0, frame.img.lastIndexOf("."))}_2x${frame.img.substring(frame.img.lastIndexOf("."))} 2x`} />
                                                <img src={`${ASSETS}/${frame.img}`} alt="{banner.alt}" />
                                            </picture>
                                        </div>
                                    </div>
                                )
                            })}
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    company: state.homeReducer[state.LangReducer.lang] && state.homeReducer[state.LangReducer.lang].company ? state.homeReducer[state.LangReducer.lang].company : null,
 })
export default withRouter(connect(mapStateToProps)(CompanyComponent));