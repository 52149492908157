import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from "react-router-dom"
import {HOME_PAGE} from "../constants/routePaths";
import { ASSETS } from "../constants/paths";
import PageService from "../pageService/pageService";
import { COOKIE_CONSENT_KEY } from '../constants/strings';
class ErorrComponent extends Component {
    pageService = null
    constructor(props) {
        super(props);
        this.pageService = new PageService(props.location.pathname);
        this.pageService.getData(props.dispatch);
    }
    componentDidMount(){
        this.script()
    }

    script = () =>{
        var head = document.querySelector("head");
        var script = document.createElement("script")
        if (this.props.activeLang === "en"){
            script.setAttribute("id","cookie-bundle")
            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=en-US`)
            head.appendChild(script);
        }else{
            script.setAttribute("id","cookie-bundle")
            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=tr-TR`)
            head.appendChild(script);
        }
    }
    render() {
        let {activeLang} =this.props
        return (
            <div className='erorr-root'>
                <div className="erorr-wrapper safe-area">
                    <div className="erorr-container">
                        <div className="text-block">
                        <h2>404</h2>
                        <p> Üzgünüz! Aradığınız sayfayı bulamadık.</p>
                        <Link to={HOME_PAGE.replace(":lang",activeLang)} className="btn-item">
                        Anasayfaya Git
                        </Link>
                            </div>
                        
                        <div className="image-block">
                            <div className="image-item up">
                                <picture>
                                            <source media="(min-width: 769px;)" srcSet={`${ASSETS}/img/request-image.png 1x, 
                                    ${ASSETS}/img/request-image-2x.png 2x `} type="image/png" />
                                            <source media="(min-width: 769px;)" srcSet={`${ASSETS}/img/request-image.webp 1x, 
                                    ${ASSETS}/img/Articles-Image/request-image-2x.webp 2x `} type="image/webp" />
                                            <img src={`${ASSETS}/img/request-image.png`} alt="Request-Image" />
                                </picture>
                            </div>
                            <div className="image-item">
                                <picture>
                                            <source media="(min-width: 769px;)" srcSet={`${ASSETS}/img/request_img.png 1x, 
                                    ${ASSETS}/img/request_img_2x.png 2x `} type="image/png" />
                                            <source media="(min-width: 769px;)" srcSet={`${ASSETS}/img/request_img.webp 1x, 
                                    ${ASSETS}/img/Articles-Image/request_img_2x.webp 2x `} type="image/webp" />
                                            <img src={`${ASSETS}/img/request_img.png`} alt="request_img" />
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
   
})
export default connect(mapStateToProps) (ErorrComponent);
