import {RECEIVE_LAYOUT} from '../constants/actionTypes'

const initialState = {};


const LayoutReducer = (state = initialState, action) => {
    switch(action.type){
        case RECEIVE_LAYOUT:
            return{
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default LayoutReducer;