import async from 'async';
import * as actionTypes from '../constants/actionTypes';
import * as webService from '../services/webService';

const receivePrivacy =(payload) => ({
  type:actionTypes.RECEIVE_PRIVACY,
  payload
})


export const getPrivacyInit = (dispatch) => {
     return new Promise ((resolve)=>{
         console.log("11111111111111111111111111",resolve)
       async.series([
         (cb)=>{
              webService.getPrivacy().then((res)=>{
                if(res.data.success){
                  cb (null,res.data.success)
                }else{
                  cb(null,false)
                }
              })
                  .catch((err)=>{
                    cb(null,false)
                  })
         }
       ], (err,result)=>{
            dispatch(receivePrivacy(result[0]));
            resolve(result)
           }
       )
     })
}