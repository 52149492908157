import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ASSETS} from "../../constants/paths";
import {Link} from "react-router-dom";
import {REQUEST_DEMO} from "../../constants/routePaths";




class BannerComponent extends Component {
    render() {
        let {banner,activeLang} =this.props
        return (
            <div className="banner-root">
                <div className="banner-wrapper">
                    <div className="banner-container">
                        {
                            banner &&
                            <div className="banner-block">
                                <div className="img-block">
                                    <picture>
                                        <source  srcSet={`${ASSETS}${banner.img.substring(0, banner.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${banner.img.substring(0, banner.img.lastIndexOf("."))}_2x.webp 2x`} />
                                        <source  srcSet={`${ASSETS}${banner.img} 1x, ${ASSETS}${banner.img.substring(0, banner.img.lastIndexOf("."))}_2x${banner.img.substring(banner.img.lastIndexOf("."))} 2x`} />
                                        <img src={`${ASSETS}${banner.img}`} alt={banner.alt} />
                                    </picture>
                                </div>
                                <div className="right-block">
                                    <div className="head-item">
                                        <h2 dangerouslySetInnerHTML={{__html: banner.title}}/>
                                        <div className="icon-item">
                                            <svg width="64" height="33" viewBox="0 0 64 33" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M60.5943 5.1407C48.5823 27.4766 24.5872 36.5575 2.30101 23.9828C1.55909 23.5641 0.618263 23.8259 0.199546 24.5587C-0.219171 25.3045 0.042555 26.2466 0.78447 26.6653C24.6723 40.1559 50.4351 30.5385 63.3107 6.6062C63.7137 5.86036 63.4324 4.91812 62.6826 4.51249C61.9329 4.11994 60.9973 4.39486 60.5943 5.1407Z"
                                                      fill="black"/>
                                                <path
                                                    d="M21.0555 14.4034C23.5368 14.4034 25.5483 12.392 25.5483 9.91068C25.5483 7.42941 23.5368 5.41797 21.0555 5.41797C18.5742 5.41797 16.5628 7.42941 16.5628 9.91068C16.5628 12.392 18.5742 14.4034 21.0555 14.4034Z"
                                                    fill="black"/>
                                                <path
                                                    d="M39.6547 9.62426C42.0875 9.62426 44.0597 7.65214 44.0597 5.21936C44.0597 2.78658 42.0875 0.814453 39.6547 0.814453C37.2219 0.814453 35.2498 2.78658 35.2498 5.21936C35.2498 7.65214 37.2219 9.62426 39.6547 9.62426Z"
                                                    fill="black"/>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="text-item">
                                        <p>{banner.text}</p>
                                    </div>
                                    <div className="bottom-item">
                                    <Link  to={`${REQUEST_DEMO.replace(":lang",activeLang)}`}>{banner.btnText}</Link>
                                </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    banner: state.homeReducer[state.LangReducer.lang] && state.homeReducer[state.LangReducer.lang].banner ? state.homeReducer[state.LangReducer.lang].banner : null,

})

export default connect(mapStateToProps)(BannerComponent);