export const scrollToElement = (refEl,pathName) => {
    let elementPosition = getElementPosition(refEl);
    setTimeout(()=>{
        window.scrollTo({
            top: elementPosition.y-65,
            left: 0,
            behavior: 'smooth'
        });
    },50)
};
export const scrollToTop=()=>{
    window.scrollTo({
        top:0,
        left:0,
        behavior:'smooth'
    })
}
export const checkLetter=value=>{
    if (value){
        const values = value.replace(/[`!@#$%^&*()_+-={};':"|,.<>?~]/,'')
        return values
    }
}
export const getElementPosition = (el) => {
    var xPos = 0;
    var yPos = 0;

    while (el) {
        if (el.tagName === "BODY") {
            xPos += (el.offsetLeft + el.clientLeft);
            yPos += (el.offsetTop + el.clientTop);
        } else {
            // for all other non-BODY elements
            xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
            yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }
        el = el.offsetParent;
    }
    return {
        x: xPos,
        y: yPos
    };
};
export const normalizePhone = value => {
    if (!value) {
        return value
    }

    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length <= 3) {
        return `${onlyNums.length>0 ? "(":""}${onlyNums}${onlyNums.length>3?")":""}`

    }
    if (onlyNums.length <= 6) {
        return `(${onlyNums.slice(0, 3)})${onlyNums.length>3?" ":""}${onlyNums.slice(3)}`
    }
    return `(${onlyNums.slice(0, 3)})${onlyNums.length>3?" ":""}${onlyNums.slice(3, 6)}${onlyNums.length>6?" ":""}${onlyNums.slice(6,8)}${onlyNums.length>8?" ":""}${onlyNums.slice(8,10)}`
}

export const maxLen100 = (value) => {
    return value ? value.slice(0, 10) : "";
}
export const checkTcNo = (value) => {
    if (!value) {
        return value
    }
    let term = value;
    if (value.length > 11) {
        term = value.substring(0, 11)
    }
    const onlyNums = term ? term.replace(/[^\d]/g, '') : null

    return `${onlyNums}`;
};