import React, { Component } from 'react'
import CookieAcceptLightBox from '../layout/lightBox/cookieAcceptLightBox'
import { connect } from 'react-redux'

 class CookieFormNotice extends Component {

    state = {
        acceptLbOpen: false
    }

    toggleCookieLb = () => {
        this.setState({
            acceptLbOpen: !this.state.acceptLbOpen
        })
    }



  render() {
    let {notice} = this.props;
    return (
        <div>
            {
                notice &&
                <div className='cookie-notice-root'>
                <div className='notice-container'>
                    <div className='icon-block'>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="64"
                            height="64"
                            fill="none"
                            viewBox="0 0 64 64"
                            >
                            <circle cx="32" cy="32" r="27" stroke="#3430FF" strokeWidth="4"></circle>
                            <path
                                stroke="#3430FF"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="6"
                                d="M32 21v13M32 43v1"
                            ></path>
                        </svg>
                    </div>
                    <div className='text-block'>
                        <span>{notice.title}</span>
                        <p>{notice.desc}</p>
                    </div>
                    <div className='btn-block' onClick={() => this.toggleCookieLb()}>
                        <span>{notice.btn_txt}</span>
                    </div>
                </div>
            </div>
            }
            
            {
                this.state.acceptLbOpen &&
                <CookieAcceptLightBox toggleCookieLb={this.toggleCookieLb}/>
            }
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
    notice: state.contactReducer[state.LangReducer.lang] ? state.contactReducer[state.LangReducer.lang].cookieNotice : null,
})

export default connect(mapStateToProps) (CookieFormNotice)