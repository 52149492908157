import React, {Component} from 'react';
import {RECEIVE_LAYOUT} from "../constants/actionTypes";
import * as ROUTES from '../constants/routePaths';
import * as webService from '../services/webService';
import {getHomeDataInit} from './homeService';
import {ENLANG, TRLANG} from "../constants/path";
import Paths from "../helper/path"
import {HOME} from "../constants/routePaths";
import {TERMS_OF_SERVICE} from "../constants/routePaths";
import {getPrivacyInit} from "./privacyService";
import {getPricingDataInit} from "./pricingService";
import {getCookieDataInit} from "./cookiePolicyService";
import {getContactDataInit, getFormDataInit} from "./contactService";


const receiveLayout = (payload)=>({
    type:RECEIVE_LAYOUT,
    payload
});

export default class PageService  {
    currentPage = ROUTES.HOME_PAGE;

    constructor(url) {
        this.currentPage = url;
        this.checkLang()
    };
    checkLang = () => {
        let locationArr = this.currentPage.split("/");
        if (locationArr[1] === ENLANG) {
            this.pathGenerator(ENLANG);
        }else if(locationArr[1] === TRLANG){
            this.pathGenerator(TRLANG);
        }
        else{
            this.pathGenerator(TRLANG);
        }
    };
    pathGenerator(lang) {
        this.paths = new Paths(lang, ROUTES.routePaths);
    };
    getData = async (dispatch, urlObj, params = {}) =>{
        return new Promise(async(resolve,reject)=>{
            await webService.getLayout()
                .then((res) => {
                    if (res.data.success) {
                        dispatch(receiveLayout(res.data.success));
                    }
                });
            if(this.paths) {
                switch(this.currentPage){
                    case this.paths.home:
                    case this.paths.features:
                    case this.paths.howItWorks:
                    case this.paths.faq:
                        getHomeDataInit(dispatch)
                            .then((res)=>{
                                resolve(res);
                            });
                        break;
                    case this.paths.termsOfService:
                        getPrivacyInit(dispatch)
                            .then((res)=>{
                                resolve(res);
                            });
                        break;
                    case this.paths.contactPage:
                    case this.paths.contactFormPage:
                        getContactDataInit(dispatch)
                        .then((res)=>{
                            resolve(res);
                        });
                        break;
                    case this.paths.requestDemo:
                        getFormDataInit(dispatch)
                        .then((res)=>{
                            resolve(res);
                        });
                        break;
                    case this.paths.pricingPage:
                        getPricingDataInit(dispatch)
                        .then((res)=>{
                            resolve(res);
                        });
                        break;

                    case this.paths.cookiePage:
                        getCookieDataInit(dispatch)
                        .then((res)=>{
                            resolve(res);
                        })
                        break;
                    default:
                        resolve(false);
                }

            }
        })
    }












}

