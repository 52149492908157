import React, {Component} from "react";
import RoutesComponent from "./components/routesComponent";

class App extends Component{
    render() {
        return (
            <div>
                <RoutesComponent/>
            </div>
        );
    }
}


export default App;
