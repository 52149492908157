import React, { Component } from "react";

class LoaderComponent extends Component {

    render() {
        return (
         <div className='lightBox-root'>
                <div className="lightBox-wrapper">
                    <div className="lightBox-container">
                        <div className="lds-ring">
                        <div className="lds-item one" ></div>
                        <div className="lds-item two"></div>
                        <div className="lds-item three" ></div>
                        <div className="lds-item four"></div>
                        </div>
                 
                        </div>
                    </div>
            </div>
        )
    }
}

export default LoaderComponent;
