import React, { Component } from 'react';

class RadioCheckboxComponent extends Component {
    render() {
        let {input,options,title,btnid,err_txt, btn_id, meta: { submitFailed, error, touched }} = this.props;
        return(
            
            <div className={`checkbox-block general  ${touched && error ? "input-alert" : ""}`}>
                <h3>{title}</h3>

                {
                    options.map((optionItem,key)=>
                        <div key={key} className="checkbox-item">
                            <input value={optionItem.name}  id={optionItem.name} name={btn_id} onChange={input.onChange} type="radio"  />
                            <label className="overlay-button" htmlFor={optionItem.name} >
                        <p className="checkbox-text-item">{optionItem.name}</p>

                                
                                </label>
                        </div>
                    )

                }
                    <div className="alert-text">
                        <span>{err_txt}</span>
                    </div>
            </div>
        )
    }
}

export default RadioCheckboxComponent;
