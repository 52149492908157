import React, {Component} from 'react';
import {RECAPCTHA_KEY} from "../../constants/strings";
import ReCAPTCHA from "react-google-recaptcha";

class RecaptchaFieldComponent extends Component {
    recaptchaRef = React.createRef();

    onRecaptchaChange = (value) => {
        this.props.input.onChange(value);
    };

    onRecaptchaExpired = () => {
        this.props.input.onChange(null);
    };

    render() {
        const {meta: {error, submitFailed},reCaptchaErr,lang} = this.props;

        return (
            <div className={`recaptcha-wrapper ${error && submitFailed ? "error" : ""}`}>
                <ReCAPTCHA
                    ref={this.recaptchaRef}
                    sitekey={RECAPCTHA_KEY}
                    onChange={this.onRecaptchaChange}
                    onExpired={this.onRecaptchaExpired}
                    hl={lang?lang:"tr"}
                />
               {
                  (submitFailed&&error)&&
                   <p className="err-txt">{reCaptchaErr}

                   </p>
               }
            </div>
        );
    }
}

export default RecaptchaFieldComponent;
