import React, {Component, Fragment} from 'react';
import FaqListItemComponent from "./faqListItemComponent";
import FaqListGeneralComponent from "./faqListGeneralComponent";
import {connect} from "react-redux";
import {FAQ} from "../../constants/routePaths";
import {scrollToElement} from "../../helper";
import {withRouter} from "react-router-dom";

class FaqComponent extends Component {
   faqRoot=React.createRef();
   state = {
      isListen: "",
      isOpen: true
   }
   componentDidMount() {
      console.log("this.props.location.pathname----",this.props.location.pathname,"-----",FAQ.replace(":lang",this.props.activeLang),"activeLang",this.props.activeLang);
      if(this.props.location.pathname === FAQ.replace(":lang",this.props.activeLang)){
         /**@TODO timeout olmadan yapılabilir mi kontrol edilecek*/
         setTimeout(() => {
            scrollToElement(this.faqRoot.current);
         }, 500);
      }
      // window.addEventListener('scroll', this.handleScroll)
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevProps.location.pathname!==this.props.location.pathname && this.props.location.pathname === FAQ.replace(":lang",this.props.activeLang)){
         scrollToElement(this.faqRoot.current);
      }
   }

   toggleListMenu = (event) => {
      if (event === "general") {
         this.setState({
            isListen: event,
            isOpen: false
         })

      } else {
         this.setState({
            isListen: event,
            isOpen: true
         })

      }

   }

   render() {
      let {faq}= this.props
      /*todo:ssr olacak mı?*/
      return (
                <div className="faq-root" ref={this.faqRoot} >
                   <div className="faq-container">
                      <div className="head-block">
                         <h3>{faq.title}</h3>
                         {/* <p>Do you have a question about the 'GDPR Cookie Consent'? See the list below for our most frequently
                      asked questions.
                      If your question is not listed here, then please visit the <a href="#">support page.</a></p>*/}
                      </div>
                      {/*<div className="top-block">
                   <span className={`${this.state.isOpen ? "active" : " "}`}
                         onClick={() => this.toggleListMenu("license")}>License</span>
                   <span className={`${this.state.isListen === "general" ? "active" : " "}`}
                         onClick={() => this.toggleListMenu("general")}>General</span>
                </div>*/}
                      <div className={`bottom-container ${this.state.isOpen ? "active" : ""} `}>
                         <ul>
                         {
                              faq.faqArr.length>0 && faq.faqArr.map((item,index)=>
                                  <FaqListItemComponent {...item}/>
                              )
                         }
                         </ul>
                      </div>
                   </div>
                </div>
      );
   }
}
const mapStateToProps = (state) => ({
   activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
   faq: state.homeReducer[state.LangReducer.lang] && state.homeReducer[state.LangReducer.lang].faq ? state.homeReducer[state.LangReducer.lang].faq : null,

})
export default withRouter( connect(mapStateToProps)(FaqComponent));
