import React, {Component} from 'react';

class RadioInputContactComponent extends Component {
    render() {
        let {input,options,inputText,err_txt,meta: { submitFailed, error, touched },btnid} = this.props;
        return(
           
            <div  className={`checkbox-block  ${touched && error  ? "input-alert" : ""}   `}>


                <div className="checkbox-item" >
                    <input type="radio" name="radio" id={btnid}/>
                    <label htmlFor={btnid}  className="overlay-button">
                        <p className="checkbox-text-item">{inputText}
                        </p>
                    </label>
                </div>
                <div className="alert-text">
                    <span>{err_txt}</span>
                </div>
            </div>
        )
    }
}

export default RadioInputContactComponent;
