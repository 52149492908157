import React, { Component } from 'react';
import { connect } from 'react-redux';
import ContactFormComponent from '../../components/forms/contactFormComponent';
import PageService from "../../pageService/pageService";
import { ASSETS } from "../../constants/paths";

class ContactFormPage extends Component {
    pageService = null
    constructor(props) {
        super(props);
        this.pageService = new PageService(props.location.pathname);
        this.pageService.getData(props.dispatch);
    }
    render() {
        return (
            <div className="request-demo-root contact-us-form">
            <div className="request-demo-wrapper">
                <div className="request-demo-form-container">
                    <ContactFormComponent/>
                 
                  
                </div>
            </div>
        </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
  
})

export default connect(mapStateToProps) (ContactFormPage);
