import React, {Component} from 'react';
import {connect} from "react-redux";

class FaqListItemComponent extends Component {

    state = {
        isOpen: false
    }

    toggleListMenu = (num) => {
      /*  let faq = document.querySelectorAll(".faq-block");
        faq[num].classList.toggle("active")*/
            this.setState({
                isOpen: !this.state.isOpen
            })


    }
    render() {
        let {question,answer}=this.props;
        return (

                <li className={`faq-block  ${this.state.isOpen ? "active" : ""}`} >
                    <div className="faq-item" onClick={()=>this.toggleListMenu()} >
                        <span>{question}</span>
                        <div className="icon-item" >
                        </div>
                    </div>
                    <div className="faq-drop-block">
                        <p>{answer}</p>
                    </div>
                </li>

        );
    }
}



export default connect() (FaqListItemComponent);