import React, { Component } from 'react';
import { connect } from 'react-redux';
import PageService from "../../pageService/pageService";
import LoadingComponent from '../loadingComponent';
import { ASSETS } from "../../constants/paths"
import { COOKIE_CONSENT_KEY } from '../../constants/strings';
class CookieTextPage extends Component {
    pageService = null
    constructor(props) {
        super(props);
        this.pageService = new PageService(props.location.pathname);
        this.pageService.getData(props.dispatch);
    }
    componentDidMount(){
        this.script()
    }

    script = () =>{
        var head = document.querySelector("head");
        var script = document.createElement("script")
        if (this.props.activeLang === "en"){
            script.setAttribute("id","cookie-bundle")

            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=en-US`)
            head.appendChild(script);
        }else{
            script.setAttribute("id","cookie-bundle")

            script.setAttribute("src",`https://cdn.cookiesuit.com/sdk/cookie-bundle.js?key=${COOKIE_CONSENT_KEY}&lang=tr-TR`)
            head.appendChild(script);
        }
    }
    render() {
        let { cookie } = this.props;
        return (
            <div className='text-root'>
                {
                    (!cookie) && <LoadingComponent />
                }
                {
                    cookie &&
                    <>
                        <div className="text-head-block">
                            <h1>{cookie.title}</h1>

                        </div>
                        <div className="text-wrapper safe-area">
                            <div  className="text-container">
                                {
                                    cookie.desc.map((obj, index) => {
                                        return (
                                            <div className='text-item' key={index} dangerouslySetInnerHTML={{ __html: obj.text }}>
          
                                                 </div>
                                      
                                        )
                                    })
                                }

                                {/* ---------------------------------------------------------------------------------------------> */}

                                {/* {
                                    cookie.desc2.map((obj, index) => {
                                        return (

                                            <p key={index}>
                                                <h2>
                                                    {obj.title}

                                                </h2>
                                                {obj.text}
                                            </p>
                                        )
                                    })
                                } */}

                                {/* ---------------------------------------------------------------------------------------------> */}

                                {
                                    cookie.teble.map((obj, index) => {
                                        return (

                                            <div className="table-block" key={index}>
                                                <span>
                                                    {obj.title}

                                                </span>
                                                <table>

                                                    <tr>
                                                        {
                                                            obj.column.map((objCol, indexCol) => {
                                                                return (
                                                                    <th key={indexCol} >{objCol.text}</th>

                                                                )
                                                            })
                                                        }

                                                    </tr>

                                                    {
                                                        obj.rows&&
                                                        obj.rows.map((objRow, indexRow) => {
                                                            return (

                                                                <tr key={indexRow}>
                                                                    {
                                                                        objRow&&
                                                                        objRow.texts.map((objTxt,indexTxt) => {
                                                                            return (
                                                                                <td key={indexTxt} >{objTxt.text}</td>

                                                                            )
                                                                        })
                                                                    }


                                                                </tr>

                                                            )
                                                        })
                                                    }



                                                </table>
                                            </div>
                                        )
                                    })
                                }


{
                                    cookie.desc2.map((obj, index) => {
                                        return (
                                            <div key={index} className='text-item' dangerouslySetInnerHTML={{ __html: obj.text }}>
          
                                                 </div>
                                      
                                        )
                                    })
                                }


                                {/* {
                                    cookie.desc3.map((obj, index) => {
                                        return (

                                            <p key={index}>
                                                <h2>
                                                    {obj.title}

                                                </h2>
                                                {obj.text}
                                                {
                                                    obj.icon &&
                                                    <picture>

                                                        <img src={`${ASSETS}${obj.icon}`} alt="icon" />
                                                    </picture>
                                                }
                                                {
                                                    obj.text3 &&
                                                    obj.text3}

                                                <ul>
                                                    {
                                                        obj.list &&
                                                        obj.list.map((list, indexList) => {
                                                            return (
                                                                <>
                                                                    {list.link ?

                                                                        <li className='link-policy' key={indexList}>{list.text} <a href={list.link}>{list.link}</a> </li>
                                                                        :
                                                                        <li key={indexList}>{list.text}</li>
                                                                    }

                                                                </>

                                                            )
                                                        })
                                                    }

                                                </ul>
 
 {
 obj.text2_1 &&                       
                        <>
    {
                                                    obj.text2_1 &&

                                                    obj.text2_1 
                                                }
                                                <a href="/tr/contact-form">www.cookiesuit.com/contact-form</a>
                                                {
                                                    obj.text2_2 &&

                                                    obj.text2_2 
                                                }
 </> }
                                            </p>
                                        )
                                    })
                                } */}


                                {/* ---------------------------------------------------------------------------------------------> */}

                            </div>
                        </div>
                    </>

                }

            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    cookie: state.cookiePolicyReducer[state.LangReducer.lang] && state.cookiePolicyReducer[state.LangReducer.lang].cookie ? state.cookiePolicyReducer[state.LangReducer.lang].cookie : null,


})
export default connect(mapStateToProps)(CookieTextPage);
