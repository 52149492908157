import React, {Component} from 'react';

class FaqListGeneralComponent extends Component {
    state = {
        isOpen: false
    }
    toggleListMenu = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }
    render() {
        return (
            <div>
                <li className={`faq-block ${this.state.isOpen ? "active" : ""}`}>
                    <div className="faq-item" onClick={this.toggleListMenu}>
                        <span>Is buying a plugin a one-time GENERAL?</span>
                        <div className="icon-item" >
                        </div>
                    </div>
                    <div className="faq-drop-block">
                        <p>The plugin license comes as a 12-months subscription. The license is valid for one year and
                            will renew after that automatically unless canceled.
                            You can use the plugin for lifetime, but support and updates of the plugins will be
                            available as long as the license is active.
                            The cookie scanning feature of the plugin, however, will only work with an active valid
                            license.
                        </p>
                    </div>
                </li>
            </div>
        );
    }
}

export default FaqListGeneralComponent;