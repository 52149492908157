import axios from "axios";
import {WEB_SERVICE, API_URL} from "../constants/paths";


export const  getHome = () => {
    return axios.get(`${WEB_SERVICE}home.json`);

}
export const request = (params) => {
    return axios.post(`${WEB_SERVICE}request/request`,"params="+JSON.stringify(params));
}

export const getLayout = () => {
    return axios.get(`${WEB_SERVICE}layout.json`);
}

export const getPrivacy = () =>{
    return axios.get(`${WEB_SERVICE}termsOfService.json`)
}

export const getContact = () =>{
    return axios.get(`${WEB_SERVICE}contact.json`)
}
export const getForm = () =>{
    return axios.get(`${WEB_SERVICE}form.json`)
}
export const getPricing = () =>{
    return axios.get(`${WEB_SERVICE}pricing.json`)
}
export const getCookiePolicy = () =>{
    return axios.get(`${WEB_SERVICE}policy.json`)
}
export const submitForm = (params) => {
    return axios.post(`${API_URL}contact/addForm`,"params="+JSON.stringify(params));
};
export const addContactForm = (params) => {
    return axios.post(`${API_URL}contact/addContactForm`,"params="+JSON.stringify(params));
};