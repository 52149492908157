import React, {Component} from 'react';
import {ASSETS} from "../../constants/paths";
import {connect} from "react-redux";
import {HOW_IT_WORKS} from "../../constants/routePaths";
import {scrollToElement} from "../../helper";
import {withRouter} from "react-router-dom";
import LoaderComponent from '../loaderComponent';

class HowItWorksComponent extends Component {
    howItWorksRoot=React.createRef()

    componentDidMount() {
        if(this.props.location.pathname === HOW_IT_WORKS.replace(":lang",this.props.activeLang)){
            /**@TODO timeout olmadan yapılabilir mi kontrol edilecek*/
            setTimeout(() => {
                scrollToElement(this.howItWorksRoot.current);
            }, 500);
        }
        // window.addEventListener('scroll', this.handleScroll)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.location.pathname!==this.props.location.pathname && this.props.location.pathname === HOW_IT_WORKS.replace(":lang",this.props.activeLang)){
            scrollToElement(this.howItWorksRoot.current);
        }
    }


    render() {
        let {howItWorks} = this.props;
        return (
            <div className="how-it-work-root" ref={this.howItWorksRoot}>
                {
                    howItWorks && 
                    <div className="how-it-work-container safe-area">
                        {
                            (!howItWorks) && 
                            <LoaderComponent/>
                        }
                        <div className="head-item">
                            <h3>{howItWorks.title}</h3>
                            <div className="icon-item">
                                <svg width={21} height={21} viewBox="0 0 21 21" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M9.34695 7.88968C7.3976 6.28729 5.81806 4.37903 4.6311 2.15577C4.39371 1.71294 3.84134 1.54402 3.39395 1.78141C2.95112 2.01881 2.78218 2.57121 3.01957 3.0186C4.32522 5.45186 6.0509 7.54273 8.18742 9.30034C8.57547 9.61991 9.15523 9.56512 9.47479 9.17707C9.79436 8.78446 9.73499 8.20924 9.34695 7.88968Z"
                                          fill="#3430FF"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M8.30744 1.64904C8.40788 3.49339 8.4992 5.33774 8.61333 7.18209C8.64529 7.68426 9.07898 8.06774 9.58116 8.03578C10.0833 8.00382 10.4668 7.57013 10.4349 7.06796C10.3207 5.22817 10.2294 3.3884 10.129 1.54405C10.1016 1.04325 9.66788 0.657485 9.16571 0.685789C8.66353 0.714094 8.27549 1.1455 8.30744 1.64904Z"
                                          fill="#3430FF"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M15.085 2.02339C13.9163 3.78556 12.5924 5.41076 11.2594 7.05424C10.9398 7.44685 10.9991 8.02209 11.3917 8.33709C11.7843 8.65666 12.3596 8.59729 12.6746 8.20468C14.0441 6.52012 15.4046 4.84468 16.6098 3.03229C16.8883 2.61229 16.7695 2.04622 16.3495 1.76774C15.9341 1.48926 15.3634 1.60339 15.085 2.02339Z"
                                          fill="#3430FF"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M19.6121 8.57449C16.4439 8.71601 13.0793 8.72058 9.91103 8.51971C9.40886 8.49232 8.97516 8.87123 8.94321 9.37797C8.91125 9.88014 9.29473 10.3138 9.7969 10.3458C13.0291 10.5467 16.4621 10.5421 19.6943 10.4006C20.1965 10.3777 20.5891 9.94863 20.5662 9.44646C20.5434 8.94428 20.1143 8.55166 19.6121 8.57449Z"
                                          fill="#3430FF"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M8.185 9.15418C10.1389 11.5464 12.1613 13.8929 13.9737 16.3946C14.2705 16.8009 14.8411 16.8922 15.252 16.5955C15.6583 16.2987 15.7496 15.7281 15.4528 15.3218C13.6176 12.7926 11.5724 10.4187 9.5957 7.99917C9.27613 7.61112 8.70089 7.55179 8.31284 7.87136C7.92024 8.19092 7.86544 8.76614 8.185 9.15418Z"
                                          fill="#3430FF"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M8.77669 9.84837C9.288 12.341 9.42952 14.724 9.46604 17.2486C9.47061 17.7508 9.88605 18.1525 10.3882 18.1479C10.895 18.1388 11.2967 17.7279 11.2876 17.2212C11.251 14.5779 11.1004 12.0899 10.5663 9.47859C10.4613 8.98554 9.98193 8.66595 9.48432 8.77095C8.99128 8.87139 8.67169 9.35532 8.77669 9.84837Z"
                                          fill="#3430FF"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M8.85912 9.64768C7.37999 12.6059 6.61759 15.8929 5.03346 18.7873C4.79151 19.2255 4.95582 19.7825 5.39865 20.0244C5.84147 20.2664 6.3939 20.102 6.63586 19.6637C8.22912 16.7511 9.00062 13.4459 10.4934 10.4648C10.7171 10.0129 10.5345 9.46506 10.0871 9.2368C9.63518 9.0131 9.08738 9.19572 8.85912 9.64768Z"
                                          fill="#3430FF"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M9.67315 8.97625C6.72859 9.49668 3.78858 9.73407 0.825759 10.0856C0.323585 10.1449 -0.0325016 10.5969 0.0268462 11.0991C0.086194 11.6013 0.538193 11.9573 1.04037 11.898C4.03515 11.5465 7.01166 11.2999 9.99275 10.7749C10.4858 10.6836 10.8191 10.2089 10.7323 9.71581C10.641 9.2182 10.1708 8.88494 9.67315 8.97625Z"
                                          fill="#3430FF"/>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                          d="M11.7221 7.58877C11.1149 7.56138 10.5397 7.52028 9.94623 7.43354C9.44406 7.3605 8.98296 7.70745 8.90992 8.20506C8.83688 8.70267 9.18381 9.16833 9.68142 9.24138C10.3388 9.33725 10.9734 9.3829 11.6399 9.41029C12.1421 9.43311 12.5712 9.04505 12.5895 8.53832C12.6123 8.03614 12.2243 7.6116 11.7221 7.58877Z"
                                          fill="#3430FF"/>
                                </svg>
                            </div>
                        </div>
                        <div className="content-container">
                            {
                                howItWorks.workArr.length > 0 && howItWorks.workArr.map((workItem, index) =>

                                    index % 2 === 0 ?


                                        <div key={index} className="content-block">
                                            <div className="text-block">
                                                {/*<h5>Audit Your Website for Cookies and Tracking Technologies
                                </h5>*/}
                                                <ul>
                                                    {workItem.desc.map((item, inx) => {

                                                        return (
                                                            <li key={index + "-" + inx}>
                                                                <svg width={30} height={30} viewBox="0 0 30 30"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M20.1775 11.1089C19.9441 10.8755 19.63 10.7439 19.3008 10.7439C18.9717 10.7439 18.6576 10.8725 18.4242 11.1089L13.2392 16.267L11.5787 14.5945C11.1 14.1158 10.3131 14.1098 9.82841 14.5855C9.34073 15.0612 9.33475 15.8481 9.81346 16.3388L12.3506 18.8939C12.584 19.1273 12.8981 19.2589 13.2302 19.2589C13.5593 19.2589 13.8705 19.1303 14.1039 18.8969L20.1715 12.8652C20.6502 12.3865 20.6562 11.5996 20.1805 11.1149L20.1775 11.1089Z"
                                                                        fill="#3430FF"/>
                                                                    <path
                                                                        d="M28.8182 9.16725C28.0612 7.38107 26.9782 5.7774 25.6019 4.39813C24.2226 3.02184 22.6189 1.93877 20.8328 1.18181C18.9837 0.397926 17.021 0 14.9985 0C6.72883 0.00299192 0 6.73182 0 15.0015C0 23.2712 6.72883 30 14.9985 30C23.2682 30 29.997 23.2712 29.997 15.0015C29.997 12.979 29.6021 11.0163 28.8182 9.16725ZM14.9985 27.5197C8.09614 27.5197 2.4803 21.9039 2.4803 15.0015C2.4803 8.09913 8.09614 2.4833 14.9985 2.4833C21.9009 2.4833 27.5167 8.09913 27.5167 15.0015C27.5167 21.9039 21.9009 27.5197 14.9985 27.5197Z"
                                                                        fill="#3430FF"/>
                                                                </svg>
                                                                <p>{item.text}</p>
                                                            </li>
                                                        )
                                                    })}

                                                </ul>
                                            </div>
                                            <div className="img-item">
                                                <picture>
                                                    <source
                                                        srcSet={`${ASSETS}${workItem.img.substring(0, workItem.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${workItem.img.substring(0, workItem.img.lastIndexOf("."))}_2x.webp 2x`}/>
                                                    <source
                                                        srcSet={`${ASSETS}${workItem.img} 1x, ${ASSETS}${workItem.img.substring(0, workItem.img.lastIndexOf("."))}_2x${workItem.img.substring(workItem.img.lastIndexOf("."))} 2x`}/>
                                                    <img src={`${ASSETS}${workItem.img}`} alt="{banner.alt}"/>
                                                </picture>
                                            </div>
                                        </div>
                                        :

                                        <div key={index} className="content-block">
                                            <div className="img-item">
                                                <picture>
                                                    <source
                                                        srcSet={`${ASSETS}${workItem.img.substring(0, workItem.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${workItem.img.substring(0, workItem.img.lastIndexOf("."))}_2x.webp 2x`}/>
                                                    <source
                                                        srcSet={`${ASSETS}${workItem.img} 1x, ${ASSETS}${workItem.img.substring(0, workItem.img.lastIndexOf("."))}_2x${workItem.img.substring(workItem.img.lastIndexOf("."))} 2x`}/>
                                                    <img src={`${ASSETS}${workItem.img}`} alt="{banner.alt}"/>
                                                </picture>
                                            </div>
                                            <div className="text-block">
                                                {/*<h5>Audit Your Website for Cookies and Tracking Technologies
                                </h5>*/}
                                                <ul>
                                                    {workItem.desc.map((item, inx) => {

                                                        return (
                                                            <li key={index + "-" + inx}>
                                                                <svg width={30} height={30} viewBox="0 0 30 30"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M20.1775 11.1089C19.9441 10.8755 19.63 10.7439 19.3008 10.7439C18.9717 10.7439 18.6576 10.8725 18.4242 11.1089L13.2392 16.267L11.5787 14.5945C11.1 14.1158 10.3131 14.1098 9.82841 14.5855C9.34073 15.0612 9.33475 15.8481 9.81346 16.3388L12.3506 18.8939C12.584 19.1273 12.8981 19.2589 13.2302 19.2589C13.5593 19.2589 13.8705 19.1303 14.1039 18.8969L20.1715 12.8652C20.6502 12.3865 20.6562 11.5996 20.1805 11.1149L20.1775 11.1089Z"
                                                                        fill="#3430FF"/>
                                                                    <path
                                                                        d="M28.8182 9.16725C28.0612 7.38107 26.9782 5.7774 25.6019 4.39813C24.2226 3.02184 22.6189 1.93877 20.8328 1.18181C18.9837 0.397926 17.021 0 14.9985 0C6.72883 0.00299192 0 6.73182 0 15.0015C0 23.2712 6.72883 30 14.9985 30C23.2682 30 29.997 23.2712 29.997 15.0015C29.997 12.979 29.6021 11.0163 28.8182 9.16725ZM14.9985 27.5197C8.09614 27.5197 2.4803 21.9039 2.4803 15.0015C2.4803 8.09913 8.09614 2.4833 14.9985 2.4833C21.9009 2.4833 27.5167 8.09913 27.5167 15.0015C27.5167 21.9039 21.9009 27.5197 14.9985 27.5197Z"
                                                                        fill="#3430FF"/>
                                                                </svg>
                                                                <p>{item.text}</p>
                                                            </li>
                                                        )
                                                    })}

                                                </ul>
                                            </div>
                                        </div>
                                )}

                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    howItWorks: state.homeReducer[state.LangReducer.lang] && state.homeReducer[state.LangReducer.lang].howItWorks ? state.homeReducer[state.LangReducer.lang].howItWorks : null,

})
export default withRouter(connect(mapStateToProps)(HowItWorksComponent));
