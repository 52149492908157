import async from 'async';
import * as actionTypes from '../constants/actionTypes';
import * as webService from '../services/webService';

const  receivePricing = (payload) =>({
    type:actionTypes.RECEIVE_PRICING,
    payload

})

export const getPricingDataInit = (dispatch) => {
    return new Promise ((resolve,failure)=>{
        async.series([
             (cb)=> {
                webService.getPricing().then((res)=>{
                    if(res.data.success){
                        cb (null,res.data.success)

                    }else {
                        cb(null,false)
                    }
                })
                    .catch((err)=>{
                        cb(null,false)
                    })


        }
        ], (err,result)=>{
            dispatch(receivePricing(result[0]));
            resolve(result)
            }
        )

    })
}